import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { FaBars, FaHome, FaCartArrowDown, FaUser,FaSignOutAlt,FaCartPlus } from "react-icons/fa";
import { AiFillAppstore } from "react-icons/ai";
import { BsFillCartDashFill } from "react-icons/bs";
import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";



const SideBar = ({ children }) => {
  const [expire, setExpire] = useState("");
  const [role, setRole] = useState("");
  const navigate = useNavigate();


  useEffect(() => {
    refreshToken();
    
},[]);// eslint-disable-line react-hooks/exhaustive-deps

  const axiosJWT = axios.create();
  axiosJWT.interceptors.request.use(async(config) => {
    const curretDate = new Date();
    if(expire * 1000 < curretDate.getTime()){
        const response = await axios.get(`${process.env.REACT_APP_MASTER_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        const decoded = jwt_decode(response.data.accessToken);
        setRole(decoded.role);
        console.log("ini jwt: ",role);
        setExpire(decoded.exp);
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

const refreshToken = async() => {
try {
      const response = await axios.get(`${process.env.REACT_APP_MASTER_URL}/token`);
      const decoded = jwt_decode(response.data.accessToken);
      setRole(decoded.role);
      setExpire(decoded.exp);

} catch (error) {
    if(error.response){
      navigate("/login");
    }
}
}

const routes = [
  {
    path: "/",
    name: "Dashboard",
    icon: <FaHome />,
  },
  { 
    path: "/users",
    name: "User",
    icon: <FaUser />
  },
  {
    path: "/users/logout",
    name: "LogOut",
    icon: <FaSignOutAlt />,
  },
];

const routes2 = [
  {
    path: "/",
    name: "Dashboard      ",
    icon: <FaHome />,
  },
  {
    path: "/users/logout",
    name: "LogOut",
    icon: <FaSignOutAlt />,
  },
];
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);


  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "45px" : "200px",

            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}
        >
          <div className="top_section">
            <AnimatePresence>
              {!isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                >
                  BPR Nusamba 
                </motion.h1>
              )}
            </AnimatePresence>

            <div className="bars">
              <FaBars onClick={toggle} />
            </div>
          </div>
          
          <section className="routes">
            
           {role === "superAdmin" ? routes.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={!isOpen}
                  />
                );
              }

              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                  activeClassName="active"
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {!isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            }) : routes2.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={!isOpen}
                  />
                );
              }

              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                  activeClassName="active"
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {!isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })} 
          </section>
        </motion.div>

        <main>{children}</main>
      </div>
    </>
  );
};

export default SideBar;
