import "./App.css";
import {BrowserRouter, Routes, Route}  from "react-router-dom";
import Login from "./component/Login";
import LogOut from "./component/LogOut";
import RegisterUser from "./component/RegisterUser";
import User from "./component/UserList";
import Pengajuan from "./component/PengajuanList";
import FormPengajuan from "./component/FormPengajuan";
import DetailPengajuan from "./component/DetailPengajuan";
import React from 'react'
// import Navbar from "./component/Navbar";
// import SideBar from "./component/sidebar/Sidebar";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/formpengajuan" element={<FormPengajuan/>}/>
        <Route path="/detailpengajuan/:id" element={<DetailPengajuan/>}/>
        <Route path="/" element={<Pengajuan/>}/>

        <Route path="/login" element={<Login/>}/>
        <Route path="/users/register" element={<RegisterUser/>}/>
        <Route path="/users/logout" element={<LogOut/>}/>
        <Route path="/users" element={<><User/></>}/>
      </Routes>
      
    </BrowserRouter>

  );
}

export default App;
