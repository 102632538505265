import React, {useState,useEffect} from "react";
import axios from 'axios';
import jwt_decode from "jwt-decode";
import {useNavigate} from "react-router-dom";

const RegisterUser = () => {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState(null);
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");
    const [expire, setExpire] = useState("");
    const [confPassword, setConfPassword] = useState("");
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
        
    },[]);

    const refreshToken = async() => {
        try {
              const response = await axios.get(`${process.env.REACT_APP_MASTER_URL}/token`);
              setToken(response.data.accessToken);
              const decoded = jwt_decode(response.data.accessToken);
              setExpire(decoded.exp);
              if (decoded.role === "admin") {
                navigate("/")
                }
              
        } catch (error) {
            if(error.response){
              navigate("/login");
            }
        }
      }
      
      const axiosJWT = axios.create();
      
      axiosJWT.interceptors.request.use(async(config) => {
          const curretDate = new Date();
          if(expire * 1000 < curretDate.getTime()){
              const response = await axios.get(`${process.env.REACT_APP_MASTER_URL}/token`);
              config.headers.Authorization = `Bearer ${response.data.accessToken}`;
              setToken(response.data.accessToken);
              const decoded = jwt_decode(response.data.accessToken);
              setExpire(decoded.exp);
          }
          return config;
      }, (error) => {
          return Promise.reject(error);
      });
      
      // ----END---

    const saveRegister = async (e) =>{
        e.preventDefault();
        let formData = new FormData();
            formData.append('username',username);
            formData.append('email',email);
            formData.append('role',role);
            formData.append('password',password);
            formData.append('confPassword',confPassword);
        try {
            await axios.post(`${process.env.REACT_APP_MASTER_URL}/users`,formData,{
                headers:{ 
                    'Content-Type':'multipart/form-data'
                }
            });
            navigate("/users")
        } catch (error) {
            if(error.response){
               setMsg(error.response.data.msg);
            }
            
        }
            
    }
  return (
    <section className="hero has-background-grey-light is-fullheight is-fullwidth">
      <div className="hero-body">
        <div className="container">
            <div className="columns is-centered">
                <div className="column is-4-desktop">
                    <form className="box" onSubmit={saveRegister}>
                    <p style={{color:"red"}} className="has-text-centered">{msg}</p>
                    <div className="filed mt-5">
                            <label className="label">Username</label>
                            <div className="controls">
                                <input required type="text" className="input" value={username} onChange={(e)=> setUsername(e.target.value)} placeholder="Username"/>
                            </div>
                        </div>
                        <div className="filed mt-5">
                            <label className="label">Email</label>
                            <div className="controls">
                                <input required type="text" className="input" value={email} onChange={(e)=> setEmail(e.target.value)} placeholder="Email"/>
                            </div>
                        </div>
                        <div className='field'>
                    <label className='label'>Role</label>
                    <div className='control'>
                        <div className='select is-fullwidth'>
                            <select required value={role} onChange={(e)=> setRole(e.target.value)}>
                                <option value=''>--Silahkan Pilih--</option>
                                <option value="superAdmin">Super Admin</option>
                                <option value="admin">Admin</option>
                            </select>
                        </div>
                    </div>
                </div>
                        <div className="filed mt-5">
                            <label className="label">Password</label>
                            <div className="controls">
                                <input required type="password" className="input" value={password} onChange={(e)=> setPassword(e.target.value)} placeholder="*****"/>
                            </div>
                        </div>
                        <div className="filed mt-5">
                            <label className="label">Konfirmasi Password</label>
                            <div className="controls">
                                <input required type="password" className="input" value={confPassword} onChange={(e)=> setConfPassword(e.target.value)} placeholder="*****"/>
                            </div>
                        </div>
                        <div className="filed mt-5">
                            <button className="button is-link is-fullwidth">Register</button>
                        </div>
                    </form>
                </div>

            </div>
          
        </div>
      </div>
    </section>
  )
}

export default RegisterUser