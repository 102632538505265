//rafce
import React, {useState,useEffect,useRef,useMemo} from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import Dialog from "./Dialog";
import MaterialReactTable from 'material-react-table';
import SideBar from "./sidebar/Sidebar";
import RiseLoader from "react-spinners/RiseLoader";

//DONT DELETE!!
// Sourch Code reference from : https://www.telerik.com/kendo-react-ui/components/excelexport/
//                    https://www.npmjs.com/package/material-react-table
const styleTittle = {

  fontSize: 20,
  color: "#4a54f1",
  textAlign: "center",
  paddingBottom: "20px",
  paddingTop: "5px",

}

const stylespinner = {
  position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
}

const User = () => {

  useEffect(() => {
    refreshToken();
    getUsers();
    
},[]);

    const [users,setUsers] = useState([]);
    const [role, setRole] = useState("");
    const [token, setToken] = useState("");
    const [expire, setExpire] = useState("");
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

// START ---TOKEN--- 
const refreshToken = async() => {
  try {
        const response = await axios.get(`${process.env.REACT_APP_MASTER_URL}/token`);
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setRole(decoded.role);
        setExpire(decoded.exp);
        

  } catch (error) {
      if(error.response){
        navigate("/login");
      }
  }
}

const axiosJWT = axios.create();

axiosJWT.interceptors.request.use(async(config) => {
    const curretDate = new Date();
    if(expire * 1000 < curretDate.getTime()){
        const response = await axios.get(`${process.env.REACT_APP_MASTER_URL}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setRole(decoded.role);
        setExpire(decoded.exp);
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

// ----END---

const getUsers = async () => {
    const response = await axiosJWT.get(`${process.env.REACT_APP_MASTER_URL}/users`,{
        headers:{
            Authorization:`Bearer ${token}`
        }
    });
    // console.log("ini hasil get",response.data[0]);
    setUsers(response.data);
    setLoading(false);
}

//DELETE
const idUsersRef = useRef();
const [dialog, setDialog] = useState({
    message:"",
    isLoading:false,
    username:""
});

const handleDialog = (message, isLoading, username)=> {
    setDialog({
        message,
        isLoading,
        username
    });
};

const handleDelete = (idUser,username) => {
    handleDialog("Yakin Ingin Hapus?",true,username);
    idUsersRef.current = idUser;
    
};

const areUSureDelete = (choose) => {
    if (choose) {
      deleteuser(idUsersRef.current);   
      handleDialog("", false);
    } else {
      handleDialog("", false);
    }
  };

const deleteuser = async (idUser) =>{
    try {
        await axios.delete(`${process.env.REACT_APP_MASTER_URL}/users/${idUser}`);
        getUsers();
    } catch (error) {
        console.log(error);
        
    }
}
  
  //Table
  const columns = useMemo(
    () => [
      {
        accessorKey: "username", //simple recommended way to define a column
        header: "Nama User",
        muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong> //optional custom cell render
      },
      {
        accessorKey: "email", //simple recommended way to define a column
        header: "Alamat Email",
        muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong> //optional custom cell render
      },
      {
        accessorKey: "role", //simple recommended way to define a column
        header: "Role User",
        muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong> //optional custom cell render
      },
      {
        accessorKey: "#", //simple recommended way to define a column
        header: "Aksi",
        muiTableHeadCellProps: { sx: { color: "red" } },
         //custom props
        Cell: ( props ) => ( <a> <button
                                                onClick={() => handleDelete(props.row.original.idUser,props.row.original.username )}
                                                className="button is-small is-danger">
                                                Delete 
                                              </button>
                                          </a> 
                            ),
      },
    ],
    []
  ); 

  
  return (
    <div>
    {loading ?
      <div style={stylespinner}>
        <RiseLoader
          color={'#301E67'}
          loading={loading}
          size={20}
          hidden={100}
          textAlign='centered'
          aria-label="Loading Spinner"
          data-testid="loader"
        />
       </div> 
        :
    <SideBar>
    <div className="columnms mt-5 is centered">
        <img src={`${process.env.REACT_APP_MASTER_URL}/assets/logonusamba.png`}  alt="Not Found" width={300} height={300}></img>
            <label className='label' style={styleTittle}><center>DAFTAR USER <p/>BPR NUSAMBA BRONDONG</center></label>
            <Link to={'/users/register'} className="button is-link">Tambah User</Link>

              <MaterialReactTable 
                            enableRowNumbers
                            rowNumberMode="original"
                            initialState={{ columnVisibility: { '#': true } } }
                            columns={columns} data={users} 
                            enableStickyHeader
                            enableStickyFooter
                            muiTableProps={{
                              sx: {
                                tableLayout: 'fixed',
                              },
                            }}
                            defaultColumn={{
                            minSize: 10, //allow columns to get smaller than default
                            maxSize: 200, //allow columns to get larger than default
                            size: 260, //make columns wider by default
                        }}
                    />
            {dialog.isLoading && (
                <Dialog
                nameProduct={dialog.username}
                onDialog={areUSureDelete}
                message={dialog.message}
                />
                )}

      </div>
      </SideBar>
    }
  </div>
  )
}

export default User