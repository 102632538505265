//rafce
import React, {useState,useEffect,useRef,useMemo} from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Dialog from "./Dialog";
import CurrencyFormat from 'react-currency-format';
import MaterialReactTable from 'material-react-table';
import { ExcelExport,ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { process } from "@progress/kendo-data-query";
import SideBar from "./sidebar/Sidebar";
import RiseLoader from "react-spinners/RiseLoader";
// import products from "./products.json";

// Sourch Code reference from : https://www.telerik.com/kendo-react-ui/components/excelexport/
//                    https://www.npmjs.com/package/material-react-table

const stylespinner = {
  position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
}

const Pengajuan = () => {

    useEffect(() => {
      refreshToken();
      getPengajuan();
    },[]);

    const _exporter = React.createRef();
    const [userId, setUserId] = useState("");
    const [role, setRole] = useState("");
    const [token, setToken] = useState("");
    const [expire, setExpire] = useState("");   
    const [pengajuan,setPengajuan] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();


const getPengajuan = async () => {
    const response = await axiosJWT.get(`${process.env.REACT_APP_MASTER_URL}/pengajuan`);
    setPengajuan(response.data[0]);
    setLoading(false)
}
// console.log("coba",getPengajuan);

const idPengajuanRef = useRef();

const axiosJWT = axios.create();
axiosJWT.interceptors.request.use(async(config) => {
  const curretDate = new Date();
  if(expire * 1000 < curretDate.getTime()){
      const response = await axios.get(`${process.env.REACT_APP_MASTER_URL}/token`);
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      // console.log(decoded);
      // setUsername(decoded.username);
      // setEmail(decoded.email);
      setRole(decoded.role);
      setUserId(decoded.userId);
      // console.log("ini jwt: ",role);
      setExpire(decoded.exp);
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

const refreshToken = async() => {
try {
    const response = await axios.get(`${process.env.REACT_APP_MASTER_URL}/token`);
    setToken(response.data.accessToken);
    const decoded = jwt_decode(response.data.accessToken);
    // console.log("ini decode: ",decoded);
    // setUsername(decoded.username);
    // setEmail(decoded.email);
    setRole(decoded.role);
    setUserId(decoded.userId);
    setExpire(decoded.exp);

} catch (error) {
  if(error.response){
    navigate("/login");
  }
}
}


const [dialog, setDialog] = useState({
    message:"",
    isLoading:false,
    namaNsb:""
});

const handleDialog = (message, isLoading, namaNsb)=> {
    setDialog({
        message,
        isLoading,
        namaNsb
    });
};

const handleDelete = (idPengajuan,namaNsb)=> {
    handleDialog("Yakin Ingin Hapus?",true,namaNsb);
    idPengajuanRef.current = idPengajuan;
    
};

const areUSureDelete = (choose) => {
    if (choose) {
      deletePengajuan(idPengajuanRef.current);   
      handleDialog("", false);
    } else {
      handleDialog("", false);
    }
  };

const deletePengajuan = async (idPengajuan) =>{
    try {
        await axios.delete(`${process.env.REACT_APP_MASTER_URL}/pengajuan/${idPengajuan}`);
        getPengajuan();
    } catch (error) {
        console.log(error);
        
    }
}
  
  //Table
  const columns = useMemo(
    () => [
      
        {
            accessorKey: "idPengajuan", //simple recommended way to define a column
            header: "Aksi",
            muiTableHeadCellProps: { sx: { color: "red" } },
             //custom props
            Cell: ( props ) => ( <> <a><button
                                        onClick={() => handleDelete(props.row.original.idPengajuan,props.row.original.namaNsb )}
                                        className="button is-small is-danger">
                                        Delete 
                                     </button>
                                     <Link to={`detailpengajuan/${props.row.original.idPengajuan}`} className="button is-small is-link">Detail</Link>
                                     {/* <button
                                        className="button is-small is-link">
                                        Proses
                                      </button> */}
                                   </a>
                                </>
                                ),
          },
        {
            accessorKey: "fotoKTP", 
            header: "Foto KTP",
            muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
            Cell: ({ cell }) => <a href={`${process.env.REACT_APP_MASTER_URL2}/${cell.getValue()}`} download={`${pengajuan.namaNsb}.jpg`}>
            <img src={`${process.env.REACT_APP_MASTER_URL2}/${cell.getValue()}`} name="ok.jpg" alt="Not Found" width={150} height={150}></img>
            </a> //optional custom cell render
          },  
      {
        accessorKey: "namaNsb", //simple recommended way to define a column
        header: "Nama Nasabah",
        muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong> //optional custom cell render
      },
      // {
      //   accessorKey: "jenisKelamin", //simple recommended way to define a column
      //   header: "Jenis Kelamin",
      //   muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
      //   Cell: ({ cell }) => <strong>{cell.getValue()}</strong> //optional custom cell render
      // },
      {
        accessorKey: "alamat", //simple recommended way to define a column
        header: "Alamat",
        muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong> //optional custom cell render
      },
      {
        accessorKey: "jenisPengajuan", //simple recommended way to define a column
        header: "Jenis Pengajuan",
        muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong> ,
        size:5//optional custom cell render
      },
      {
        accessorKey: "nominal", //simple recommended way to define a column
        header: "Nominal",
        muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        Cell: ({ cell }) => <strong><CurrencyFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} prefix={'Rp.'} /></strong>
        //optional custom cell render
      },
      {
        accessorKey: "nomorTelp", //simple recommended way to define a column
        header: "Nomor Telp",
        muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        Cell: ({ cell }) =>  <b>{cell.getValue()}</b>
        //optional custom cell render
      },
     
      {
        accessorKey: "createdAt", //simple recommended way to define a column
        header: "Tanggal",
        muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
        Cell: ({ cell }) => <b>{cell.getValue()}</b> //optional custom cell render
      },
    ],
    []
  ); 

  //Data Excel
  const data = process(pengajuan,{}).data;
    const excelExport = () => {
      if (_exporter.current) {
        _exporter.current.save();
      }
    };
  
  return (
    <div>
    {loading ?
      <div style={stylespinner}>
        <RiseLoader
          color={'#301E67'}
          loading={loading}
          size={20}
          hidden={100}
          textAlign='centered'
          aria-label="Loading Spinner"
          data-testid="loader"
        />
       </div> 
        :
    <SideBar>
    <div className="columnms mt 5 is centered">
        <img src={`${process.env.REACT_APP_MASTER_URL2}/assets/pengajuan/logonusamba.png`}  alt="Not Found" width={300} height={300}></img>
        <div className="column is-half"></div>
            <Link to={'formpengajuan'} className="button is-warning">Pengajuan</Link>
            <button className="button is-success"onClick={excelExport}>
                    Export to Excel
            </button>
            <ExcelExport
                        data={data}
                        collapsible={true}
                        fileName="Pengajuan.xlsx"
                        ref={_exporter}>

                        <ExcelExportColumn field="namaNsb" title="Nama Nasabah" width={200} />
                        <ExcelExportColumn field="jenisKelamin" title="Jenis Kelamin" />
                        <ExcelExportColumn field="alamat" title="Alamat"/>
                        <ExcelExportColumn field="nomorTelp" title="nomor Telepon" />
                        <ExcelExportColumn field="jenisPengajuan" title="Jenis Pengajuan" />
                        <ExcelExportColumn field="nominal" title="Nominal" />
                        <ExcelExportColumn field="createdAt" title="Tanggal Pengajuan" />
            </ExcelExport>
            <MaterialReactTable 
                columns={columns} 
                data={pengajuan} 
    
                defaultColumn={{
                  minSize: 10, //allow columns to get smaller than default
                  maxSize: 10, //allow columns to get larger than default
                  size: 120, //make columns wider by default
                }}
            />
            {dialog.isLoading && (
                <Dialog
                //Update
                nameProduct={dialog.namaNsb}
                onDialog={areUSureDelete}
                message={dialog.message}
                />
                )}

    </div>
    </SideBar>
    }
    </div>
  )
}

export default Pengajuan