import {React, useState, useEffect} from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { getMonth, getYear, getDate } from 'date-fns';
import {useNavigate, useParams} from "react-router-dom";
import CurrencyInput from 'react-currency-input-field';

const DetailPengajuan = () => {

    useEffect(() => {
        getPengajuanByid();
        refreshToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

const {id} = useParams();
const [namaNsb, setNamaNsb] = useState("");
const [alamat, setAlamat] = useState("");
const [nomorTelp, setNomorTelp] = useState("");
const [jenisKelamin, setJenisKelamin] = useState("");
const [createdAt, setCreatedAt] = useState("");
const [tanggalPengajuan, setTanggalPengajuan] = useState("");
const [jenisPengajuan, setJenisPengajuan] = useState("");
const [nominal, setNominal] = useState();
const navigate = useNavigate();
const styleTittle = {
    fontSize: 20,
    color: "#4a54f1",
    textAlign: "center",
    paddingBottom: "60px",

}

const [role, setRole] = useState("");
const [token, setToken] = useState("");
const [expire, setExpire] = useState("");

const axiosJWT = axios.create();
axiosJWT.interceptors.request.use(async(config) => {
  const curretDate = new Date();
  if(expire * 1000 < curretDate.getTime()){
      const response = await axios.get(`${process.env.REACT_APP_MASTER_URL}/token`);
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setToken(response.data.accessToken);
      const decoded = jwt_decode(response.data.accessToken);
      // console.log(decoded);
      // setUsername(decoded.username);
      // setEmail(decoded.email);
      setRole(decoded.role);
      // console.log("ini jwt: ",role);
      setExpire(decoded.exp);
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

const refreshToken = async() => {
try {
    const response = await axios.get(`${process.env.REACT_APP_MASTER_URL}/token`);
    setToken(response.data.accessToken);
    const decoded = jwt_decode(response.data.accessToken);
    // console.log("ini decode: ",decoded);
    // setUsername(decoded.username);
    // setEmail(decoded.email);
    setRole(decoded.role);
    setExpire(decoded.exp);
    

} catch (error) {
  if(error.response){
    navigate("/login");
  }
}
}

const getPengajuanByid = async () =>{
    const response = await axiosJWT.get(`${process.env.REACT_APP_MASTER_URL}/pengajuandetail/${id}`)
    setNamaNsb(response.data.namaNsb);
    setJenisKelamin(response.data.jenisKelamin);
    setAlamat(response.data.alamat);
    setNomorTelp(response.data.nomorTelp);
    setJenisPengajuan(response.data.jenisPengajuan);
    setNominal(response.data.nominal);
    setTanggalPengajuan(response.data.tanggalPengajuan);
    
    // setTanggalPengajuan(`${getDate(response.data.createdAt)}-${getMonth(createdAt)+1}-${getYear(createdAt)}`)
    // setTanggalPengajuan(createdAt);
    
}


  return (  
    <div className="section is-fullheight" style={{ 
        backgroundImage: `url("${process.env.REACT_APP_MASTER_URL}/assets/nusambabrondong.jpg")`, 
        filterImage:'blur(8px)'}} >
    <div className='field' ></div>
      <div className="container ">
        <div className="column is-6 is-offset-3">
          <div className="box" >
                <img src={`${process.env.REACT_APP_MASTER_URL}/assets/logonusamba.png`}  alt="Not Found" width={300} height={300}></img>
                <label className='label' style={styleTittle}><center>DETAIL PENGAJUAN</center></label>
            <form>
                <div className='field'>
                    <label className='label'>Nama Lengkap</label>
                    <div class='control' has-icons-left has-icons-right>
                        <input required disabled type='text' className="input" value={namaNsb} onChange={(e)=> setNamaNsb(e.target.value)} placeholder='Nama Lengkap'></input>                    
                    </div>
                </div>
                <div className='field'>
                    <label className='label'>Jenis Kelamin</label>
                    <div className='control'>
                        <input required disabled type='text' className="input" value={jenisKelamin} onChange={(e)=> setJenisKelamin(e.target.value)} placeholder='Nama Lengkap'></input>         
                    </div>
                </div>
                <div className='field'>
                    <label className='label'>Alamat Lengkap</label>
                    <div className='control'>
                        <input required disabled type='text' className="input" value={alamat} onChange={(e)=> setAlamat(e.target.value)} placeholder='Alamat Saat Ini'></input>
                    </div>
                </div>
                <div className='field'>
                    <label className='label'>Nomor Telpon</label>
                    <div className='control'>
                        <input disabled required type='number' className="input" value={nomorTelp} onChange={(e)=> setNomorTelp(e.target.value)} placeholder='Nomor Telpon'></input>
                    </div>
                </div>
                <div className='field'>
                    <label className='label'>Jenis Pengajuan</label>
                    <div className='control'>
                        <input disabled required type='text' className="input" value={jenisPengajuan} onChange={(e)=> setJenisPengajuan(e.target.value)} placeholder='Nomor Telpon'></input>
                    </div>
                </div>
                <div className='field'>
                    <label className='label'>Nominal</label>
                    <div className='control'>
                       <CurrencyInput
                            required
                            disabled
                            className='input'
                            prefix='Rp.'
                            value={nominal}
                            onValueChange={(value, name) => setNominal(value)}
                            placeholder="Please enter a number"
                            maxLength={10}
                            decimalsLimit={2}
                            />  
                    </div>
                </div>
                <div className='field'>
                    <label className='label'>Tanggal Pengajuan</label>
                    <div className='control'>
                        <input disabled required type='text' className="input" value={tanggalPengajuan} onChange={(e)=> setTanggalPengajuan(e.target.value)} placeholder='Nomor Telpon'></input>
                    </div>
                </div>
                <div className='field'>
                     <button type="button" onClick={() => navigate(-1)}  className='button is-primary'>Kembali</button>
                </div>
            </form>
        </div>
    </div>
    </div>
    </div>
  
  );
}

export default DetailPengajuan