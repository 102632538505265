import React,{useState} from 'react';
import axios from 'axios';
import {useNavigate, Link, redirect} from "react-router-dom";
import CurrencyInput from 'react-currency-input-field';
import RiseLoader from "react-spinners/RiseLoader";

const stylespinner = {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  }

const FormPengajuan = () => {
const [namaNsb, setnamaNsb] = useState("");
const [alamat, setalamat] = useState("");
const [nomorTelp, setnomorTelp] = useState("");
const [jenisKelamin, setjenisKelamin] = useState("-----");
const [jenisPengajuan, setjenisPengajuan] = useState("-----");
const [nominal, setnominal] = useState();
const [image, setImage] = useState("https://fakeimg.pl/350x200/");
const [saveImage, setsaveImage] = useState(null);
const [loading, setLoading] = useState(false);
const navigate = useNavigate();


function handleUploadChange(e){
    // console.log(e.target.files[0].size);
    if (e.target.files[0].size >= 5000000){
        window.alert('Ukurun File Terlalu Besar. Maksimal File Kurang Dari 5 MB...');
    }else{
        let uploaded = e.target.files[0];
        setImage(URL.createObjectURL(uploaded));
        setsaveImage(uploaded);
    }
    
    
}

const styleTitle = {
    fontSize: 22,
    color: "#4a54f1",
    textAlign: "center",
    paddingTop: "30px",
    paddingBottom: "30px",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)", // Menambahkan efek bayangan pada teks
    fontWeight: "bold", // Membuat teks menjadi tebal
    fontFamily: "Arial, sans-serif", // Mengubah jenis font menjadi Arial atau font lainnya
  };


const savePengajuan = async (e) =>{
    e.preventDefault();
    if(!saveImage){
        alert("Silahkan Unggah Foto KTP...");
    }else if(jenisKelamin==="-----"){
        alert("Silahkan Pilih Jenis Kelamin anda...");
    }else if(jenisPengajuan==="-----"){
        alert("Silahkan Pilih Jenis Pengajuan anda...");
    }else if(nominal<100000){
        alert("Silahkan Masukan Nominal Dengan Tepat...");
    }else{

    setLoading(true);

    let formData = new FormData();
        formData.append('namaNsb',namaNsb);
        formData.append('jenisKelamin',jenisKelamin);
        formData.append('alamat',alamat) ;
        formData.append('nomorTelp',nomorTelp);
        formData.append('jenisPengajuan',jenisPengajuan);
        formData.append('nominal',nominal);
        formData.append('saveImage',saveImage);
    try {
        await axios.post(`${process.env.REACT_APP_MASTER_URL}/formpengajuan`,formData,{
            headers:{ 
                'Content-Type':'multipart/form-data'
            }
        });
        alert("Terimakasih Pengajuan Anda Akan Kami Proses...Silahkan Klik OK")
        // redirect('https://bprnusamba-brondong.co.id/reborn')
        window.location.href = 'https://bprnusamba-brondong.co.id/reborn';
        
    } catch (error) {
        alert("silahkan coba kembali atau hubungi CS kami.... ");
    }
        }
}
// backgroundImage: `url("${process.env.REACT_APP_MASTER_URL}/assets/nusambabrondong.jpg")`
  return (  
    <div>
    {loading ?
      <div style={stylespinner}>
        <RiseLoader
          color={'#301E67'}
          loading={loading}
          size={20}
          hidden={100}
          textAlign='centered'
          aria-label="Loading Spinner"
          data-testid="loader"
        />
       </div> 
        :
    <div className="section is-fullheight" style={{ 
        backgroundColor: '#1b48f1', 
        filterImage:'blur(50px)'}} >
    <div className='field' ></div>
      <div className="container ">
        <div className="column is-6 is-offset-3">
          <div className="box" >
                <img src={`${process.env.REACT_APP_MASTER_URL2}/assets/pengajuan/logonusamba.png`}  alt="Not Found" width={300} height={300}></img>
                <label className="label" style={styleTitle}>
                    <center>
                        FORM PENGAJUAN <br /> BPR NUSAMBA BRONDONG
                    </center>
                </label> 
            <form onSubmit={savePengajuan}>
                <div className='field'>
                    <label className='label'>Nama Lengkap</label>
                    <div class='control' has-icons-left has-icons-right>
                        <input required type='text' className="input" value={namaNsb} onChange={(e)=> setnamaNsb(e.target.value)} placeholder='Nama Lengkap'></input>                    </div>
                </div>
                <div className='field'>
                    <label className='label'>Jenis Kelamin</label>
                    <div className='control'>
                        <div className='select is-fullwidth'>
                            <select value={jenisKelamin} onChange={(e)=> setjenisKelamin(e.target.value)}>
                            <option value="-----">--Silahkan Pilih--</option>
                                <option value="Laki-laki">Laki-laki</option>
                                <option value="Perempuan">Perempuan</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='field'>
                    <label className='label'>Alamat Lengkap</label>
                    <div className='control'>
                        <input required type='text' className="input" value={alamat} onChange={(e)=> setalamat(e.target.value)} placeholder='Alamat Saat Ini'></input>
                    </div>
                </div>
                <div className='field'>
                    <label className='label'>Nomor Telpon</label>
                    <div className='control'>
                        <input required type='number' className="input" value={nomorTelp} onChange={(e)=> setnomorTelp(e.target.value)} placeholder='Nomor Telpon'></input>
                    </div>
                </div>
                <div className='field'>
                    <label className='label'>Pengajuan Untuk</label>
                    <div className='control'>
                        <div className='select is-fullwidth'>
                            <select value={jenisPengajuan} onChange={(e)=> setjenisPengajuan(e.target.value)}>
                                <option value="-----">--Silahkan Pilih--</option>
                                {/* <option value="KREDIT">KREDIT</option> */}
                                <option value="TABUNGAN">KREDIT</option>
                                <option value="DEPOSITO">DEPOSITO</option>
                                <option value="TABUNGAN">TABUNGAN</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='field'>
                    <label className='label'>Nominal</label>
                    <div className='control'>
                       <CurrencyInput
                            required
                            className='input'
                            prefix='Rp.'
                            value={nominal}
                            onValueChange={(value, name) => setnominal(value)}
                            placeholder="Masukan Jumlah Nominal"
                            maxLength={10}
                            decimalsLimit={2}
                            />  
                    </div>
                </div>
                
                <div className='field'>
                    <label className='label'>Foto KTP</label>
                    <div className='control'>
                        <input type="file" className='form-control' id="formFile" accept='image/*' onChange={handleUploadChange} ></input>
                    </div>
                </div>
                <div className='field'>
                    <label className='label'></label>
                    <div className='control'>
                        <img src={image} width={200} height={200} className="img-thumbnail" alt="" ></img>
                    </div>
                </div>
                <div className='field'>
                    <button type='submit' className="button is-link mr-4">Simpan</button>
                    <a href='https://bprnusamba-brondong.co.id/reborn/pengajuan' type="button"  className='button is-primary'>Kembali</a>
                </div>
            </form>
        </div>
    </div>
    </div>
    </div>
  }
</div>
  );
}

export default FormPengajuan