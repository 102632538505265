import React, {useState} from "react";
import axios from 'axios';
import {useNavigate} from "react-router-dom";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [msg, setMsg] = useState("");    const navigate = useNavigate();

    const saveLogin = async (e) =>{
        e.preventDefault();
        let formData = new FormData();
            formData.append('email',email);
            formData.append('password',password);
        try {
            await axios.post(`${process.env.REACT_APP_MASTER_URL}/login`,formData,{
                headers:{ 
                    'Content-Type':'multipart/form-data'
                }
            });
            navigate("/")
        } catch (error) {
            if(error.response){
               setMsg(error.response.data.msg);
            }
            
        }
            
    }

  return (
    <section className="hero has-background-grey-light is-fullheight is-fullwidth">
      <div className="hero-body">
        <div className="container">
            <div className="columns is-centered">
                <div className="column is-4-desktop">
                   <form className="box" onSubmit={saveLogin}>
                     <img src={`${process.env.REACT_APP_MASTER_URL2}/assets/pengajuan/logonusamba.png`}  alt="Not Found" width={300} height={300}/>
                        <p style={{color:"red"}} className="has-text-centered">{msg}</p>
                        <div className="filed mt-5">
                            <label className="label">Email/Username</label>
                            <div className="controls">
                                <input type="text" className="input" value={email} onChange={(e)=> setEmail(e.target.value)} placeholder="Username"/>
                            </div>
                        </div>
                        <div className="filed mt-5">
                            <label className="label">Password</label>
                            <div className="controls">
                                <input type="password" className="input" value={password} onChange={(e)=> setPassword(e.target.value)} placeholder="*****"/>
                            </div>
                        </div>
                        <div className="filed mt-5">
                            <button className="button is-link is-fullwidth">Login</button>
                        </div>
                    </form>
                </div>

            </div>
          
        </div>
      </div>
    </section>
  )
}

export default Login